<!-- 陈旭 version 2.5.10 -->
<template>
  <div ref="parent" class="electronic-modal">
    <a-modal
      centered
      :width="658"
      :title="isApproval ? '审批' : '查看'"
      :visible="visible"
      :footer="null"
      @cancel="$emit('update:visible', false)"
      :getContainer="() => $refs.parent"
    >
      <a-spin :spinning="currentLoading">
        <section class="content show-scrollbar">
          <section>
            <!-- 流程主题/申请人 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <!-- 流程主题 -->
              <a-descriptions-item label="流程主题">{{ current?.fileName || '无' }}</a-descriptions-item>
              <!-- 申请人 -->
              <a-descriptions-item label="申请人">{{ current?.staffName || '无' }}</a-descriptions-item>
            </a-descriptions>
            <!-- 申请印章/印章类型 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <!-- 申请印章 -->
              <a-descriptions-item label="申请印章">{{ current?.sealName || '无' }}</a-descriptions-item>
              <!-- 印章类型 -->
              <a-descriptions-item label="印章类型">{{ current?.sealTypeName || '无' }}</a-descriptions-item>
            </a-descriptions>
            <!-- 签署截止日期/备注 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <!-- 签署截止日期 -->
              <a-descriptions-item label="签署截止日期">{{ current?.expireTime || '无' }}</a-descriptions-item>
              <!-- 备注 -->
              <a-descriptions-item label="备注">{{ current?.applyText || '无' }}</a-descriptions-item>
            </a-descriptions>
            <!-- 签约文件 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="1"
              size="small"
              style="margin-bottom: 18px"
              class="applyContent"
            >
              <!-- 签约文件 -->
              <a-descriptions-item :span="2" label="签约文件">
                <section v-if="!current.documentApplyFileList">无</section>

                <section style="display: inline-block; vertical-align: top; width: 100%" v-else>
                  <div v-viewer="otherOptions">
                    <div class="applyFileBox" v-for="(it, index) in current.documentApplyFileList" :key="it.fileId">
                      <div class="seal-imgs" style="position: relative">
                        <a v-if="fileTypeObj[it.suffix]">
                          <img :src="getImgUrl(it.suffix)" class="seal-img" />
                        </a>
                        <div class="downloadFileBox" @click="downloadFile(it.fullFile)" v-if="fileTypeObj[it.suffix]">
                          <i class="iconfont icon-xiazai icons" style="color: white; font-size: 18px"></i>
                        </div>
                        <img
                          v-else
                          :src="it.fullFile"
                          alt=""
                          @error="reImg($event, it.fullFile)"
                          @click="previewImg(it.fullFile, index, 'applyFile')"
                          class="seal-img"
                        />
                      </div>
                      <div style="width: 450px" :title="it.fileName" class="single-wrap">{{ it.fileName ?? '无' }}</div>
                    </div>
                  </div>
                </section>
              </a-descriptions-item>
            </a-descriptions>
            <!-- 签署信息 -->
            <a-descriptions
              title=""
              layout="vertical"
              bordered
              :column="2"
              size="small"
              class="descript"
              style="border-top: 1px solid #f0f0f0"
            >
              <!-- 签署信息 -->
              <a-descriptions-item label="签署信息">
                <div class="sign-order">
                  <span>签署顺序：</span>
                  <img :src="current.ordinal ? inOrderToSign : disorderlySigned" alt="" class="signed-icon" />
                  <span>{{ current.ordinal ? '顺序签署' : '无序签署' }}</span>
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </section>
          <!-- 发起方/接收方 -->
          <div class="signed-info-list">
            <a-card
              :title="[item.type === 0 ? '发起方' : '接收方']"
              type="inner"
              v-for="item in current?.signatories"
              :key="item.documentId"
              class="signed-info"
            >
              <!-- 标签 -->
              <template #extra>
                <!-- 标签 -->
                <div class="tag-list">
                  <div class="tag" v-if="item.operatorNodeStatus && item.signatoryType !== 1">
                    <span class="tag-name">经办人签字</span>
                  </div>
                  <div class="tag" v-if="item.legalPersonNodeStatus">
                    <span class="tag-name">法定代表人签字</span>
                  </div>
                  <div class="tag" v-if="item.companyNodeStatus">
                    <span class="tag-name">企业签章</span>
                  </div>
                </div>
              </template>

              <div class="detail-box">
                <!-- 发起方 -->
                <div v-if="item.type === 0">
                  <div class="detail-title">企业名称：{{ item.name }}</div>
                </div>
                <!-- 接收方 -->
                <div v-if="item.type === 1">
                  <!-- 企业 -->
                  <div v-if="item.signatoryType === 0">
                    <div class="detail-title">企业名称：{{ item.name }}</div>
                    <div class="detail-title">经办人姓名：{{ item.operatorName }}</div>
                    <div class="detail-title">联系方式：{{ item.operatorContact }}</div>
                  </div>
                  <!-- 个人 -->
                  <div v-if="item.signatoryType === 1">
                    <div class="detail-title">个人姓名：{{ item.operatorName }}</div>
                    <div class="detail-title">联系方式：{{ item.operatorContact }}</div>
                  </div>
                </div>
              </div>
            </a-card>
          </div>
          <!-- 审批流 sealFlowShow-->
          <approval-time-line
            :approvalList="[].concat(current.sealFlowShow || [], current.archivedFlowShow || [])"
            :archivedLength="current.archivedFlowShow ? current.archivedFlowShow.length : 0"
            :examineStatus="current.examineStatus"
            :isPrint="2"
          />
          <!-- 审批意见 -->
          <div v-if="isApproval" class="mark">
            <p>审批意见</p>
            <a-textarea
              placeholder="请输入审批意见"
              style="100%"
              @pressEnter="e => lineFeed(e)"
              v-model:value="state.mark"
              :maxlength="50"
              showCount
              :autoSize="{ minRows: 3 }"
            ></a-textarea>
          </div>
        </section>
        <!-- 取消/拒绝/同意 -->
        <div class="action-box" v-if="hasFoot">
          <a-button v-if="isApproval" class="btn close" @click="$emit('update:visible', false)">取消</a-button>
          <a-button v-if="isApproval" class="btn cancel" :loading="loadingReject" @click="comfirmApproval(4)"
            >拒绝</a-button
          >
          <a-button class="btn comfirm" @click="comfirmApproval(3)" :loading="loadingAgree">{{
            isApproval ? '同意' : '取消'
          }}</a-button>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref, watch } from 'vue'
import '@/style/jsx-label-text.scss'
import { cmsMessage, cmsNotice } from '@/utils/utils'
import { fileTypeObj } from '@/utils/constData'
import ApprovalTimeLine from '@/views/components/approvalTimeLine'
import { useRouter } from 'vue-router'
import inOrderToSign from '@/assets/images/in-order-to-sign.png'
import disorderlySigned from '@/assets/images/disorderly-signed.png'

export default defineComponent({
  components: {
    ApprovalTimeLine
  },
  emits: ['modalSubmit'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isApproval: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => ({ sealFlowShow: [] })
    },
    loadingReject: {
      type: Boolean,
      default: false
    },
    loadingAgree: {
      type: Boolean,
      default: false
    },
    currentLoading: {
      type: Boolean,
      default: true
    },
    hasFoot: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const router = useRouter()
    const state = reactive({
      mark: undefined
    })
    const imgList = ref([])
    const previewVisible = ref(false)
    const isApproval = computed(() => props.isApproval) // 是否审批
    const isVirtualListScroll = ref(0)
    const photoIndex = ref(0)

    const comfirmApproval = status => {
      console.log('status', status)
      if (!isApproval.value) {
        emit('update:visible', false)
        return
      } else {
        emit('update:loading', true)
        state.mark = state.mark?.trim()
        if (status === 4) {
          if (!state.mark) {
            cmsMessage('error', '请输入审批意见')
            return
          } else {
            emit('update:loadingReject', true)
          }
        }
        if (status === 3) {
          console.log('同意')
          emit('update:loadingAgree', true)
        }
        emit('modalSubmit', status, state.mark)
      }
    }
    const previewImg = (src, i, type) => {
      if (!src) return
      imgList.value = []
      if (type === 'applyFile') {
        props.current.documentApplyFileS?.map(item => {
          if (!fileTypeObj[item.suffix]) {
            imgList.value.push(item.fullFile)
          }
        })
      } else if (type == 'remoteFile') {
        props.current.documentRemoteVice?.map(item => {
          if (!fileTypeObj[item.suffix]) {
            imgList.value.push(item.fullFile)
          }
        })
      }
      photoIndex.value = imgList.value.indexOf(src)
      previewVisible.value = true
    }
    const reImg = (e, src, sign = 'a') => {
      const parentNode = e.target.parentNode
      const tipsSpan = document.createElement(sign)
      parentNode.style.padding = '10px'
      if (sign === 'a') {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '104px'
        tipsDiv.style.width = '104px'
        tipsDiv.style.background = '#F2F2F2'
        tipsDiv.style.textAlign = 'center'
        // tipsDiv.style.transform = 'rotateZ(180deg)'
        tipsDiv.style.color = '#B1B1B1'
        tipsDiv.style.padding = '40px 0 0 0'
        const tipsI = document.createElement('i')
        const tipsText = document.createTextNode(src ? '加载失败' : '同步失败')
        tipsI.className = 'iconfont icon-tupianjiazaishibai1 error-img'
        tipsI.style.fontSize = '30px'
        tipsI.style.color = '#D9D9D9'
        tipsI.style.marginBottom = '10px'
        tipsI.style.height = '25px'
        tipsI.style.display = 'block'
        tipsDiv.appendChild(tipsI)
        tipsDiv.appendChild(tipsText)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
        // const tipsText = document.createTextNode('1')
        // tipsSpan.appendChild(tipsText)
        // tipsSpan.addEventListener('click', () => {
        //   window.location.href = src.replace('.com//', '.com/') + '?upload=true'

        //   setTimeout(() => {
        //     cmsNotice('success', '正在为你下载，请耐心等待')
        //   }, 200)
        // })
      } else {
        const tipsText = document.createTextNode('图片未同步成功')
        tipsSpan.appendChild(tipsText)
      }

      parentNode.removeChild(e.target)
      parentNode.appendChild(tipsSpan)
    }
    // 文本框回车禁止
    const lineFeed = e => {
      e.preventDefault()
    }
    const getImgUrl = src => {
      return require('@/assets/images/' + fileTypeObj[src])
    }
    const downloadFile = src => {
      window.location.href = src.replace('.com//', '.com/') + '?upload=true'
      setTimeout(() => {
        cmsNotice('success', '正在为你下载，请耐心等待')
      }, 200)
    }
    watch(
      () => props.visible,
      newVisible => {
        if (newVisible) {
          state.mark = undefined
        }
      }
    )

    watch(
      () => props.current,
      () => {
        isVirtualListScroll.value++
      }
    )
    return {
      imgList,
      lineFeed,
      downloadFile,
      getImgUrl,
      fileTypeObj,
      state,
      comfirmApproval,
      previewVisible,
      previewImg,
      reImg,
      isVirtualListScroll,
      photoIndex,
      otherOptions: {
        navbar: false,
        title: false,
        transition: false
      },
      inOrderToSign,
      disorderlySigned
    }
  }
})
</script>

<style lang="scss" scoped>
.applyContent {
  :deep(.ant-descriptions-item-content) {
    padding: 0;
  }
}

.action-box {
  display: flex;
  justify-content: flex-end;
  padding: 10px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  .btn {
    height: 32px;
    background: #ffffff;
    border-radius: 2px;
    margin-right: 8px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 20px;
  }
  .close {
    color: #999;
    border: 1px solid #dadada;
    &:hover {
      color: #999;
      border-color: #dadada;
    }
  }
  .cancel {
    color: #c3161c;
    border: 1px solid #C3161C;
  }
  .comfirm {
    background: #c3161c;
    color: #fff;
    border: 1px solid transparent;
    margin-right: 0;
    &:hover {
      border-color: transparent;
    }
  }
}
:deep(.ant-modal-title) {
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
  line-height: 25px;
}
:deep(.ant-modal-body) {
  padding: 0;
}
:deep(.ant-timeline-item-last) {
  padding-bottom: 0;
}
.content {
  padding: 24px 24px;
  max-height: 519px;
  overflow-y: auto;
  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }
  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }
  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }
    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }
  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
  }
  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
}

.applyFileBox {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  &:hover .downloadFileBox {
    display: block;
  }
  .seal-imgs {
    margin-bottom: 0;
  }
  .downloadFileBox {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    height: 22px;
    line-height: 22px;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  &:last-child {
    border-bottom: none;
  }
}
.seal-imgs {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 3px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 11px;
  margin-bottom: 10px;
  line-height: 0;
  cursor: pointer;
  &:nth-child(7n) {
    margin-right: 0;
  }
  .seal-img {
    width: 63px;
    height: 63px;
  }
}
</style>

<style lang="less" scoped>
:deep(.ant-descriptions-item-content) {
  padding: 7px 12px !important;
}
:deep(.image-list-module) {
  //图片
  .seal-imgs {
    margin-right: 16px;
    margin-bottom: 10px;
  }
}
</style>

<style lang="less" scoped>
:deep(.ant-descriptions-item-content) {
  vertical-align: top;
}
</style>

<style lang="less" scoped>
.sign-order {
  display: flex;
  flex-direction: row;
  align-items: center;
  .signed-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
.signed-info-list {
  margin-bottom: 16px;
  .signed-info {
    border-bottom: none;
    .tag-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      .tag {
        background: linear-gradient(270deg, #dcdee0 0%, #e9eaed 100%);
        border-radius: 1px;
        padding: 1px 8px;
        margin-right: 8px;
        .tag-name {
          font-size: 12px;
          font-weight: 400;
          color: #646566;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .detail-box {
      .detail-title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        padding: 7px 12px;
        border-bottom: 1px solid #f0f0f0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid #f0f0f0;
    }
    &:first-child {
      border-top: none;
    }
  }
}
</style>

<style lang="less">
.electronic-modal {
  .ant-modal {
    .ant-modal-body {
      .ant-card-head {
        min-height: 0;
        padding: 0 12px;
        .ant-card-head-wrapper {
          .ant-card-head-title {
            padding: 7px 0;
          }
          .ant-card-extra {
            padding: 7px 0;
          }
        }
      }
      .ant-card-body {
        padding: 0;
      }
    }
  }
}
</style>
